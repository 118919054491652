import { State, STATE_CHANGED, toggleCollapsible } from "./collapsible";

export function initGrayCollapseListModule() {
  let elements = document.querySelectorAll(".gray-collapse-list");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    initGrayCollapseList(element);
  }
}

function initGrayCollapseList(element) {
  const items = Array.prototype.slice.apply(element.querySelectorAll(".gray-collapse-list__item"));
  for (const item of items) {
    const header = item.querySelector(".gray-collapse-list__header");
    const body = item.querySelector(".gray-collapse-list__body");
    if (!header || !body) {
      continue;
    }

    header.addEventListener("click", () => {
      toggleCollapsible(body);
    });
    body.addEventListener(STATE_CHANGED, event => {
      const state = event.detail.state;
      if (state === State.open || state === State.opening) {
        item.classList.add("gray-collapse-list__item--open");
      } else {
        item.classList.remove("gray-collapse-list__item--open");
      }
    });
  }
}
