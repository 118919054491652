/**
 * Обобщенный API для сворачивания и разворачивания элементов.
 */
import { layoutUpdate, timeout } from "../../utils/utils";

const duration = 0.3;

export const State = {
  open: "open",
  opening: "opening",
  closed: "closed",
  closing: "closing",
}
export const Class = {
  [State.open]: "collapsible-open",
  [State.opening]: "collapsible-opening",
  [State.closed]: "collapsible-closed",
  [State.closing]: "collapsible-closing",
}
export const STATE_CHANGED = "collapsible:statechanged";

export async function openCollapsible(element) {
  element.style.display = "";
  element.style.height = "0px";
  setCollapsibleState(element, State.opening);
  await layoutUpdate();

  let targetHeight = element.scrollHeight;
  element.style.transitionProperty = "height";
  element.style.transitionDuration = `${duration}s`;
  await layoutUpdate();

  element.style.height = `${targetHeight}px`;
  await timeout(duration * 1000);

  if (getCollapsibleState(element) !== State.opening) return;
  element.style.height = "";
  setCollapsibleState(element, State.open);
}

export async function closeCollapsible(element) {
  element.style.height = `${element.scrollHeight}px`;
  setCollapsibleState(element, State.closing);
  await layoutUpdate();

  element.style.height = "0px";
  await timeout(duration * 1000);

  if (getCollapsibleState(element) !== State.closing) return;
  element.style.height = "";
  element.style.display = "none";
  setCollapsibleState(element, State.closed);
}

export function getCollapsibleState(element) {
  if (element.style.display === "none") {
    return State.closed;
  }

  if (element.classList.contains(Class.open)) {
    return State.open;
  } else if (element.classList.contains(Class.opening)) {
    return State.opening;
  } else if (element.classList.contains(Class.closed)) {
    return State.closed;
  } else if (element.classList.contains(Class.closing)) {
    return State.closing;
  }

  return State.open;
}

function setCollapsibleState(element, state) {
  const prevState = getCollapsibleState(element);
  if (prevState === state) return;

  element.classList.remove(Class.open, Class.opening, Class.closed, Class.closing);
  element.classList.add(Class[state]);

  const event = new CustomEvent(STATE_CHANGED, {detail: {state, prevState}});
  element.dispatchEvent(event)
}

export function isCollapsibleOpen(element) {
  const state = getCollapsibleState(element);
  return state === State.open || state === State.opening;
}

export function toggleCollapsible(element) {
  if (isCollapsibleOpen(element)) {
    closeCollapsible(element);
  } else {
    openCollapsible(element);
  }
}